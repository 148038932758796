
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";

import { productMessage, productValue, validProduct } from "./validateData";

export default defineComponent({
  title: "Detail Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      noImg: "/media/no-image/No_Image_Horizontal_TKI.png",
      urlImage: {
        url1: "",
        url2: "",
        url3: "",
        url4: "",
        url5: "",
      },
      url: "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg",
      deleteImg: "./assets/images/delete-product.svg",
      uploadImg: "./assets/images/upload-product.svg",
      errorsMessage: {
        errortitle: "",
        errortype: "",
        errorprice: "",
      },
      valid: {
        ...validProduct,
      },

      detail: {
        title: "",
        createTime: "2022-02-15T13:33:33",
        creatorId: "",
        deleteBy: "",
        deleteTime: "",
        description: "",
        discount: 0,
        editorId: "",
        imageshow: "",
        imageProduct: [],
        isDelete: false,
        price: 0,
        type: "",
        category: "",
        stock: 0,
        tax: 0,
        updateTime: "",
        _id: "",
      },

      showfoto2: false,
      showfoto3: false,
      showfoto4: false,
      showfoto5: false,
      oldDataFileImage: {
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        file5: "",
      },
      targetDataImage: {
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        file5: "",
        file1Ready: "",
        file2Ready: "",
        file3Ready: "",
        file4Ready: "",
        file5Ready: "",
      },
      listCategory: [],
      listType: [],

      jumlahfoto: 1,
      imgCount: 5,
      existingIndex: {},
      uploadIndex: {},
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);
    this.getListKategori();
    this.getListType();
    this.getDetail();
  },

  methods: {
    triggerInputFile(index) {
      console.log(index);
      let element = document.getElementById(`input-file-${index + 1}`);
      element?.click();
      // console.log(element);
    },
    changeImage(item) {
      console.log(item);
      this.detail.imageshow = item;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getListKategori() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/product/util/combo/category").then(
        ({ data }) => {
          this.listCategory = data;
          this.detail.category = data[0];
        }
      );
      this.isLoading = false;
    },
    getListType() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/product/util/combo/type").then(
        ({ data }) => {
          this.listType = data;
        }
      );
      this.isLoading = false;
    },

    getDetail() {
      store.dispatch(Actions.VERIFY_AUTH);

      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();
      ApiService.getWithoutSlug(
        "crmv2/order/product/admin/" + this.$route.params._id
      )
        .then((res) => {
          this.detail = res.data;
          this.detail.imageshow = res.data.imageProduct[0];
          let existI = [];
          for (let i = 0; i < this.imgCount; i++) {
            let fotoparam = res.data.imageProduct[`image${i + 1}`];
            this.existingIndex[i] = null;
            this.uploadIndex[i] = null;
            if (fotoparam) this.existingIndex[i] = i;
            if (fotoparam)
              ApiService.getWithoutSlug(
                "crmv2/main_image/image/get/base64/" + fotoparam
              )
                .then((resImg) => {
                  this.oldDataFileImage[`file${i + 1}`] = fotoparam;
                  this.urlImage[`url${i + 1}`] =
                    "data:image/jpg;base64," + resImg.data;
                })
                .catch((err) => {
                  toast.error(err?.res?.data?.detail);
                  this.isLoading = false;
                });
          }
          console.log(this.existingIndex);

          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e?.res?.data?.detail);

          this.isLoading = false;
        });
    },
    validateForm() {
      console.log("asdasdasd");
      this.valid = { ...validProduct };
      let check = { ...productValue };
      this.errorsMessage.errortitle = "";
      this.errorsMessage.errortype = "";
      this.errorsMessage.errorprice = "";

      Object.keys(check).map((item) => {
        switch (typeof check[item]) {
          case "string":
            if (!this.detail[item] || check[item] == this.detail[item]) {
              this.errorsMessage[`error${item}`] = productMessage[item];
              this.valid[item] = false;
            }
            break;
          case "number":
            if (!this.detail[item] || this.detail[item] < check[item]) {
              this.errorsMessage[`error${item}`] = productMessage[item];
              this.valid[item] = false;
            }
            break;
        }
      });
      return Object.values(this.valid).reduce((prev, next) => prev && next);
    },

    submitData() {
      // return console.log(this.validateForm());
      if (!this.validateForm()) {
        return;
      }
      const toast = useToast();

      console.log("edit");

      this.errorsMessage.errortitle = "";
      this.errorsMessage.errortype = "";
      this.errorsMessage.errorprice = "";

      const data = {
        title: this.detail.title,
        description: this.detail.description,
        price: this.detail.price,
        stock: this.detail.stock,
        discount: this.detail.discount,
        tax: this.detail.tax,
        imageProduct: this.detail.imageProduct,
        type: this.detail.type,
        category: this.detail.category,
      };

      let rslt = "";
      let idNewProduct = this.detail._id;
      let fileImg = [];
      let image = "";

      let formData = new FormData();
      let upload = 0;
      for (let index = 0; index < this.imgCount; index++) {
        if (this.targetDataImage[`file${index + 1}Ready`]) {
          formData.append("files", this.targetDataImage[`file${index + 1}`]);
          upload++;
        }
      }
      // return console.log(FormData, data);
      this.isLoading = true;
      if (upload == 0)
        ApiService.putWithData(
          "crmv2/order/product/admin/" + idNewProduct,
          data
        )
          .then((res) => {
            toast.success("Berhasil Mengubah Data");

            this.$router.push("/superadmin/master-produk");
          })
          .catch((e) => {});
      else
        ApiService.postWithData(
          "/crmv2/main_image/image/uploads/" + idNewProduct,
          formData
        )
          .then((res) => {
            this.handleImages(res.data.process.items).map((img, i) => {
              data.imageProduct[`image${i + 1}`] = img;
            });

            ApiService.putWithData(
              "crmv2/order/product/admin/" + idNewProduct,
              data
            )
              .then((res) => {
                toast.success("Berhasil Mengubah Data");

                this.$router.push("/superadmin/master-produk");
              })
              .catch((e) => {});
          })
          .catch((e) => {
            this.isLoading = false;
            rslt = "error";
            toast.error(e.response.data.detail);
          });
      // }
    },

    handleImages(items) {
      let temIndex = 0;
      let imageResult: any[] = [];

      for (let index = 0; index < 5; index++) {
        imageResult[index] = null;
        if (this.existingIndex[index] != null) {
          imageResult[index] = this.detail.imageProduct[`image${index + 1}`];
        }
        if (this.uploadIndex[index] != null) {
          imageResult[index] = items[temIndex];
          temIndex++;
        }
      }
      return imageResult;
    },

    addfoto() {
      if (this.jumlahfoto < 5) {
        switch (this.jumlahfoto) {
          case 1:
            this.showfoto2 = true;
            break;
          case 2:
            this.showfoto3 = true;
            break;
          case 3:
            this.showfoto4 = true;
            break;
          case 4:
            this.showfoto5 = true;
        }
        let jumlahfotosekarang = this.jumlahfoto + 1;
        this.jumlahfoto = jumlahfotosekarang;
        console.log(jumlahfotosekarang);
      } else {
        const toast = useToast();
        toast.error("Foto Maksimal 5");
      }
    },

    handleFileUpload(event, key) {
      this.uploadIndex[key - 1] = key;
      this.urlImage[`url${key}`] = URL.createObjectURL(event.target.files[0]);
      // console.log(this.urlImage);
      this.targetDataImage[`file${key}`] = event.target.files[0];
      this.targetDataImage[`file${key}Ready`] = true;
      // console.log(this.targetDataImage);
    },
  },
});
